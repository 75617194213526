<template>
	<div>
		<v-container fluid>
			<v-row class="mx-md-2">
				<v-col cols="12">
					<v-card v-bind="customCard">
						<v-data-table :headers="headers" :items="accessoryGroups.data"
									  :loading="listStatus === status.RUNNING" disable-filtering disable-pagination
									  disable-sort hide-default-footer loading-text="Loading... Please wait">
							<template v-slot:top>
								<v-toolbar id="accessory-groups-toolbar" class="rounded-t align-center" flat>
									<v-toolbar-title>
										{{ filters.archived ? 'Archived Groups' : 'Groups' }}
									</v-toolbar-title>

									<v-spacer></v-spacer>

									<v-btn :disabled="filters.archived" @click="createDialog = true" class="mr-2"
										   color="primary">
										<v-icon>
											mdi-plus
										</v-icon>
									</v-btn>

									<table-filters v-model="filters" :filter-types="filterTypes"
												   right-margin></table-filters>

									<v-btn @click="refreshData" color="primary">
										<v-icon>
											mdi-refresh
										</v-icon>
									</v-btn>

								</v-toolbar>
							</template>

							<template v-slot:item.name="props">
								<v-edit-dialog v-if="!filters.archived" @save="updateAccessoryGroup(props.item)"
											   :return-value.sync="props.item.name" large>
									{{ props.item.name }}
									<template v-slot:input>
										<div class="mt-4 mb-2 text-h6">
											Update Name
										</div>
										<v-text-field v-model="props.item.name"
													  :rules="[validation.required, validation.notEmpty]" autofocus
													  counter="100" dense label="Name" outlined
													  single-line></v-text-field>
									</template>
								</v-edit-dialog>
								<div v-else>
									{{ props.item.name }}
								</div>
							</template>

							<template v-slot:item.active="{item}">
								<v-checkbox v-model="item.active" @change="updateAccessoryGroup(item)" class="ma-0 pa-0"
											color="primary" hide-details></v-checkbox>
							</template>

							<template v-slot:item.action="props">
								<v-btn v-if="!filters.archived" @click="archiveId = props.item.id" icon>
									<v-icon>
										mdi-archive
									</v-icon>
								</v-btn>
								<v-btn v-else @click="restoreId = props.item.id" icon>
									<v-icon>
										mdi-backup-restore
									</v-icon>
								</v-btn>
							</template>

						</v-data-table>

						<v-pagination v-model="filters.page" :disabled="listStatus === status.RUNNING"
									  :length="accessoryGroups.pages" :total-visible="7" class="my-4"
									  color="secondary"></v-pagination>

					</v-card>
				</v-col>
			</v-row>
		</v-container>


		<card-dialog :showing="createDialog" @cancel="createDialog = false"
					 @confirmed="createAccessoryGroup(accessoryGroup)" :confirm-disabled="!valid" confirm-text="Create"
					 max-width="600px" title="Create Accessory Group">
			<template v-slot:dialog-body>
				<v-form v-model="valid" ref="accessoryGroupForm">
					<v-row dense>
						<v-col cols="12">
							<v-text-field v-model="accessoryGroup.name" :id="newUUID()"
										  :rules="[validation.required, validation.notEmpty]"
										  autocomplete="new-password" counter="100" dense label="Name"
										  outlined></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field :value="accessoryGroupSlug" dense disabled hide-details label="Slug"
										  outlined></v-text-field>
						</v-col>
					</v-row>
				</v-form>
			</template>
		</card-dialog>

		<card-dialog :showing="archiveId !== null" @cancel="archiveId = null"
					 @confirmed="archiveAccessoryGroup(archiveId)" confirm-color="warning" confirm-text="Archive"
					 title="Archive Accessory Group" title-color="warning">
			<template v-slot:dialog-body>
				Are you sure you want to archive this accessory group?
			</template>
		</card-dialog>

		<card-dialog :showing="restoreId !== null" @cancel="restoreId = null"
					 @confirmed="restoreAccessoryGroup(restoreId)" confirm-color="warning" confirm-text="Restore"
					 title="Restore Accessory Group" title-color="warning">
			<template v-slot:dialog-body>
				Are you sure you want to restore this accessory group?
			</template>
		</card-dialog>

	</div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import CardDialog from '@/components/global/CardDialog'
import TableFilters from '@/components/global/TableFilters'

export default {
	name: 'AccessoryGroupsIndex',
	components: {TableFilters, CardDialog},
	data: () => ({
		headers: [
			{text: 'Name', align: 'left', value: 'name', width: '35%'},
			{text: 'Slug', value: 'slug', width: '25%'},
			{text: 'Active', value: 'active', width: '15%'},
			{text: 'Maintainer', value: 'lastMaintainedBy', width: '15%'},
			{text: '', value: 'action', align: 'right', width: '10%'},
		],
		valid: false,
		filters: {},
		filterTypes: [
			{
				label: 'Group Name',
				type: 'text',
				name: 'name'
			},
			{
				label: 'Archived',
				type: 'toggle',
				name: 'archived',
				required: true,
			},
		],
		accessoryGroup: {
			name: '',
		},
		createDialog: false,
		archiveId: null,
		restoreId: null,
	}),
	created() {
		this.filters = {...this.appliedFilters}
	},
	methods: {
		...mapActions('accessory/accessoryGroup', [
			'applyFilters',
			'listAccessoryGroups',
			'createAccessoryGroup',
			'updateAccessoryGroup',
			'archiveAccessoryGroup',
			'restoreAccessoryGroup',
		]),
		refreshData() {
			this.listAccessoryGroups()
		}
	},
	computed: {
		...mapState('accessory/accessoryGroup', [
			'appliedFilters',
			'accessoryGroups',
			'listStatus',
			'createStatus',
			'updateStatus',
			'archiveStatus',
			'restoreStatus'
		]),
		accessoryGroupSlug() {
			if (!this.accessoryGroup.name) return ''
			return this.accessoryGroup.name.toLowerCase().replaceAll(/\s/g, '-')
		}
	},
	watch: {
		createDialog(updated) {
			if (!updated) {
				this.$refs.accessoryGroupForm.reset()
				this.$refs.accessoryGroupForm.resetValidation()
			}
		},
		createStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.listAccessoryGroups()
				this.createDialog = false
			}
		},
		archiveStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.listAccessoryGroups()
				this.archiveId = null
			}
		},
		restoreStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.archivedAccessoryGroups()
				this.restoreId = null
			}
		},
		filters: {
			handler(updated) {
				this.applyFilters({...updated})
			},
			deep: true
		}
	}
}
</script>

<style>
.v-data-table-header-mobile {
	display: none;
}
</style>
